<template>
    <div class="SuspensionFunction">
      <div class="SuspensionFunction-title">
        <div class="title">数据报告</div>
      </div>
      <div class="dataAnalys">
        <!-- 数据展示格子 -->
        <div style="background-color: #f0f2f5; ">
          <a-row :gutter="16" >
            <a-col :span="8" style="padding-right:30px">
              <div class="DataGrid">
                <div class="boxColor1">
                  <!-- {{ dataAnalysisId }} -->
                </div>
                <div class="dataBox">
                  <div class="ViewingData">首页访问总人数(ip)
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                             <span> 即访客的独立ip数： <br>多个用户在同一网络ip地址下访问时,只计算1个ip； <br> 统计的是所有访问用户的独立ip数；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>

                  <div class="number"> <h2>{{HomepageAccess.homepageTotal}}</h2> </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="DataGrid">
                <div class="boxColor2">
                </div>
                <div class="dataBox">
                  <div class="ViewingData">首页访问总人次
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                            <span> 即页面的浏览次数： <br>用户每次访问网页均被记录； <br> 对同一页面的多次访问,访问量累计；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>
                  <div class="number"> <h2>{{HomepageAccess.homepageNumber}}</h2> </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8" style="padding-left:30px">
              <div class="DataGrid">
                <div class="boxColor3">

                </div>
                <div class="dataBox">
                  <div class="ViewingData">功能访问总人次
                    <span>
                         <a-tooltip placement="top" overlayClassName="bgc_tooltip">
                           <template #title>
                            <span> 即功能的浏览次数： <br>用户每次点击功能图标均被记录；<br> 对同一功能的多次访问,访问量累计；</span>
                           </template>
                           <a-icon type="info-circle" style="color:#ababab"/>
                         </a-tooltip>
                    </span>
                  </div>
                  <div class="number"> <h2>{{HomepageAccess.funcNumber}}</h2> </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 首页访问折线图（按周查询） -->
        <div style="width:100%;margin: auto;margin-bottom: 19px;">
          <div class="NumberOfHomepageVisitors">
            <p>首页访问总人次/人数</p>
            <div style="display: flex;">
              <div class="tabAnddateChange">
                <a-space direction="vertical" :size="10" class="dataPicker">
                  <!-- :default-value="[(moment(),dateFormat), (moment(),dateFormat)]" -->
                  <a-range-picker
                    :default-value="[startTime,endTime]"
                    :format="dateFormat"
                    @change="onChangeDay"
                  />
                </a-space>
              </div>
              <div>
                <!-- @change="searchOk" -->
                <a-radio-group  v-model="Ftype" style="margin-right: 20px" @change="searchOk">
                  <a-radio-button value="人次" >
                    人次
                  </a-radio-button>
                  <a-radio-button value="人数">
                    人数
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>
          </div>
          <!-- 折线图 -->
         <div>
          <div id="lineChart" style="width:100%;height:400px; background-color:#ffffff ;display: flex;justify-content: center;" ></div>
         </div>
        </div>
        <!-- </div> -->
        <!-- 地图 -->
        <div class="mapBox">
          <p>各省份观看人次/人数统计</p>
          <div class="map">
            <!-- 地图 -->
            <div id="mapContainer"></div>
            <!-- table -->
            <div class="mapTable">
              <div style="display: flex;justify-content: space-between;margin-bottom: 20px;">
                <div></div>
                <!-- @change="searchOk" -->
                <a-radio-group  v-model="mapRadioType" @change="mapchangeSearchOk">
                  <a-radio-button value="人次" >
                    人次
                  </a-radio-button>
                  <a-radio-button value="人数">
                    人数
                  </a-radio-button>
                </a-radio-group>
              </div>
              <a-table
                 size="small"
                :pagination="false"
                :row-key="record => record.proportionString"
                :columns="mapColumns"
                style="margin: auto;width: 100%;"
                :data-source="mapFunctionData"
              >
              <!-- :data-source="mapFunctionData" -->
              <template slot="serialNum" slot-scope="text, record, index">
                {{ index + ((mapPageNum - 1) * mapPageSize) + 1 }}
              </template>
              </a-table>
              <a-pagination
                size="small"
                :total="mapTotal"
                show-size-changer
                show-quick-jumper
                :default-current="mapPageNum"
                v-model="mapCurrent"
                :pageSizeOptions="mapPageSizeOptions"
                :pageSize = "mapPageSize"
                @showSizeChange="onMapChange"
                @change="onMapChange"
                style="margin-top: 20px;"
              />
            </div>
          </div>
        </div>
        <!-- 饼状图-->
        <div class="PieChart">
          <p>各功能访问人次/人数排名</p>
          <div class="pieLayout">
            <!-- 饼图 -->
            <div id="piepic"  ref="b">11</div>
            <!-- 表格 -->
            <div class="piepic2">
              <div style="display: flex;width:100%;justify-content: right;margin-bottom: 20px;">
                <!-- <div></div> -->
                <!-- @change="searchOk" -->
                <a-radio-group  v-model="pieDatatype" @change="functionSearchOk">
                  <a-radio-button value="人次" >
                    人次
                  </a-radio-button>
                  <a-radio-button value="人数">
                    人数
                  </a-radio-button>
                </a-radio-group>
              </div>
              <a-table
                 size="small"
                :pagination="false"
                :columns="columns"
                style="margin: auto;width: 100%;"
                :data-source="functionData"
              >
              <!-- :data-source="functionData" -->
              <template slot="serialNum" slot-scope="text, record, index">
                {{ index + ((piePageNum - 1) * piePageSize) + 1 }}
              </template>
              </a-table>
              <!-- :total="mapTotal"  -->
              <a-pagination
                size="small"
                :total="pieTotal"
                show-size-changer
                show-quick-jumper
                :default-current="piePageNum"
                v-model="pieCurrent"
                :pageSizeOptions="pageSizeOptions"
                :pageSize = "piePageSize"
                @showSizeChange="onChange"
                @change="onChange"
                style="margin-top: 20px;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>


<script src="https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json"></script>
<script>
import {getDataGrid,getuserSessions,getNumberOfHomepageVisitors,getPieAndTable,getPieAndTableNumberOfPeople,
  getMapData,getPersonTimeOfMap
} from "@/service/dataAnalysis/homePage";
import * as echarts from 'echarts';
import moment from "moment";

import china from "@/assets/china.json";

// import 'echarts/map/js/china.js' // 核心文件
// import  './components/city/cityfiles/yancheng.js' // 核心文件
// import  './components/city/cityfiles/changzhou.js' // 核心文件
// import  './components/city/cityfiles/wuxi.js' // 核心文件
// import yancheng from './components/city/cityfiles/yancheng.json' // 引入json文件
// import changzhou from './components/city/cityfiles/changzhou.json' // 引入json文件
// import wuxi from './components/city/cityfiles/wuxi.json' // 引入json文件
echarts.registerMap('china', china) // 在echarts中注册使用这个文件


  export default {
      name: "HomepageDataReport",
      data(){
      return {
        endTime:(moment().format('YYYY-MM-DD')),
        // moment().subtract(1, 'month').format('YYYY-MM-DD')
        dateFormat: 'YYYY-MM-DD',
        pieTotal:0,
        mapTotal:0,
        piePageNum:1,
        pieCurrent:1,
        piePageSize:5,
        pageSizeOptions:["5", "10", "15", "20"],//分页
        mapPageNum:1,
        mapCurrent:1,
        mapPageSize:5,
        mapPageSizeOptions:["5", "10", "15", "20"],
        HomepageAccess:'',
        Ftype: '人次',
        pieDatatype:'人次',
        mapRadioType:'人次',
        chartdata:'',
        beginDate:'',
        endDate:'',
        type:"personTime",  //人数人次切换
        functionType:'personTime',
        mapfuncitonType:'personTime',
        functionData:[],
        mapFunctionData:[],
        columns: [
        {
          title: '序号',
          dataIndex: 'serialNum',
          // width: '13%',
          key: 'serialNum',
          align: 'center',
          scopedSlots: {customRender: 'serialNum'},
        },
        {
          title: '功能名称',
          dataIndex: 'funcName',
          key: 'funcName',
          align: 'center',
          // width: '16%'
        },
        {
          title:this.functionType == 'personTime' ? '人次':'人次/人数',
          dataIndex: 'number',
          key: 'number',
          align: 'center',
          // width: '16%'
        },
        {
          title: '占比',
          dataIndex: 'proportionString',
          key: 'proportionString',
          align: 'center',
          // width: '16%'
        }
      ],
        mapColumns: [
        {
          title: '序号',
          dataIndex: 'serialNum',
          // width: '13%',
          key: 'serialNum',
          align: 'center',
          scopedSlots: {customRender: 'serialNum'},
        },
        {
          title: '省份',
          dataIndex: 'province',
          key: 'province',
          align: 'center',
          // width: '16%'
        },
        {
          title: '人次/人数',
          dataIndex: 'number',
          key: 'number',
          align: 'center',
          // width: '16%'
        },
        {
          title: '占比',
          dataIndex: 'proportionString',
          key: 'proportionString',
          align: 'center',
          // width: '16%'
        }
      ],
      }
    },
    created(){
      this.getDataGrid()
      this.getuserSessions()
      // this.chinaMapData()
    },
    mounted(){
      // 不可以在created里面挂载饼状图
      this.Pie()
      this.chinaMapData()
    },
    computed:{
      // 九宫格页面传来的id
      dataAnalysisId() {
        return this.$store.state.dataAnalysisId;
      },
      startTime(){
        let time = moment(this.$store.state.statisticsLiveCreated).format('YYYY-MM-DD')
        return time;
      }
    },
    methods:{
      // 饼状图分页
      onChange(pageNumber, pageSize) {
        this.piePageSize = pageSize
        this.piePageNum = pageNumber
        this.Pie();
      },
      // 地图分页
      onMapChange(pageNumber, pageSize) {
        this.mapPageSize = pageSize
        this.mapPageNum = pageNumber
        this.chinaMapData()
      },
      // vuex传来的id
      async getDataGrid(dataAnalysisId) {
        const response = await getDataGrid(this.$store.state.dataAnalysisId);
        if (response.code === 0) {
          this.HomepageAccess = response.data;
          // console.log("格子",this.HomepageAccess)
        }
      },
      // 1.1折线图的人次人数切换
      searchOk(e) {
        switch (e.target.value) {
        // 全部
        case '人次':
          this.type = "personTime"
          this.getuserSessions()
          break;
        // 缺陷
        case '人数':
          this.type = "NumberOfPeople"
          this.getuserSessions()
          break;
      }
      },
      // 1.2折线图日历按钮(首页访问)
      async onChangeDay(date, dateString) {
        // this.value3 = (moment(dateString).format('YYYYMM'))
        //   const newVal = this.value3
        this.beginDate = dateString[0]
        this.endDate = dateString[1]
        this.getuserSessions()
      },
      // 1折线图（首页访问总人次）
      async getuserSessions(dataAnalysisId,beginDate,endDate) {
        if (this.beginDate == "" && this.beginDate == "") {
        //   data = (moment().format('YYYYMM'))
        //   this.beginDate = (moment().format('YYYY-MM-DD'))
        //   this.beginDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
          this.beginDate = this.startTime;
          this.endDate = (moment().format('YYYY-MM-DD'))
        }
        // 人次
        var beginDate = this.beginDate ; var endDate = this.endDate
        if (this.type == "personTime"){ //人次
            const response = await getuserSessions(this.$store.state.dataAnalysisId,beginDate,endDate)
            this.chartdata = response.data;
        }else if(this.type == "NumberOfPeople"){ //人数
            const response = await getNumberOfHomepageVisitors(this.$store.state.dataAnalysisId,beginDate,endDate)
            this.chartdata = response.data;
        }
        // const response = await getuserSessions(this.$store.state.dataAnalysisId,beginDate,endDate)
        // const response = await getuserSessions(this.$store.state.dataAnalysisId,beginDate,endDate)
        //     this.chartdata = response.data;
        var myChart = echarts.init(document.getElementById('lineChart'));
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis',    //悬浮提示框
          },
          legend: {               //设置区分（哪条线属于什么）
            data:this.type == "personTime"? ['访问总人次']:['访问总人数']
          },
          grid: {
            x: 40,
            y: 86,
            x2: 60,
            y2: 60,
            // borderWidth: 10
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              // axisLabel: {         //interval设置成 0 强制显示所有标签
              //   interval: 0,
              // },
              boundaryGap: false,
              data: this.chartdata.map(item => (item.currentDate)),
            }
          ],
          yAxis: [
            {
              type: 'value',
              minInterval: 1,  //最小刻度
              splitNumber: 5,
              splitLine: {    //网格线
                lineStyle: {
                  type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                },
                show: true,//隐藏或显示
                width: 20,
                interval: '0.5',    // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
              }
            }
          ],
          dataset: [
            {
              // source: this.hhh
            },
          ],
          series: [
            {
              // 点的数据
              data: this.chartdata.map(item =>  item.number),
              name:this.type == "personTime"? '访问总人次':"访问总人数",
              type: 'line',
              lineStyle: {
                color: '#5090FF',
              },
              // 点
              itemStyle: {
                color: '#5090FF'
              },
              // 平滑 属性
              smooth: true,
              symbol: 'emptyCircle',
              // 区域渐变效果
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [{
                        offset: 0,
                        color: '#4DC9EC'
                      },
                        {
                          offset: 1,
                          color: '#B3E7F7'
                        }
                      ],
                      false
                  ),
                  shadowColor: '#ffffff',
                  shadowBlur: 10
                }
              },
            }
          ]
        })

      },

      // 分界线---------------------------------------------------------------------------------------分界线

      // 3.1饼图的人次人数切换
      functionSearchOk(e){
        switch (e.target.value) {
        // 全部
        case '人次':
          this.functionType = "personTime"
          this.Pie()
          break;
        // 缺陷
        case '人数':
          this.functionType = "NumberOfPeople"
          this.Pie()
          break;
      }
      },
      // 3.饼图
      async Pie(dataAnalysisId) {
        // 人次
        if (this.functionType == "personTime"){
            const response = await getPieAndTable(this.$store.state.dataAnalysisId,this.piePageNum,this.piePageSize)
            const peiRes = await getPieAndTable(this.$store.state.dataAnalysisId,"1","999")
            var pieData = peiRes.data
            this.functionData = response.data
            this.pieTotal = response.count
        }else if(this.functionType == "NumberOfPeople"){  //人数
            const response = await getPieAndTableNumberOfPeople(this.$store.state.dataAnalysisId,this.piePageNum,this.piePageSize)
            const peiRes = await getPieAndTable(this.$store.state.dataAnalysisId,"1","999")
            var pieData = peiRes.data
            this.functionData = response.data
            this.pieTotal = response.count
        }
        // const response = await getPieAndTable(this.$store.state.dataAnalysisId,"1","5")
        // var pieData = response.data

        var myChart = echarts.init(document.getElementById('piepic'));
        // 绘制图表
        myChart.setOption({
          //设置
          tooltip:{
              trigger:'item',
              // formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          //对图例组件的不同系列进行标记说明
          // legend:{
          //       orient:'vertical',  //设置图例列表的布局朝向
          //       left:'right',
          //       data:pieData.number
          // },
          legend: {
            orient: "vertical",
            right: "1%",
            textStyle: {
              align: "right",
              // color: "#",
            }
          },
          dataset:{
            source:pieData
          },
          series:[
            {
                radius:'60%',
                center:['35%','50%'],
                name: this.functionType == "personTime"?"人次":"人数",
                type:'pie',
                //放置要展示的数据,我没数据啊我靠，省份不会也没有把
                data: pieData.number,
            }
          ]
        })
      },

      // 分界线---------------------------------------------------------------------------------------分界线

      // 地图的人次人数切换
      mapchangeSearchOk(e){
        switch (e.target.value) {
        // 全部
        case '人次':
          this.mapfuncitonType = "personTime"
          this.chinaMapData()
          break;
        // 缺陷
        case '人数':
          this.mapfuncitonType = "NumberOfPeople"
          this.chinaMapData()
          break;
      }
      },

      // 地图（人数的）
      async chinaMapData(){
        // 人次
        if (this.mapfuncitonType == "personTime"){
          const response = await getPersonTimeOfMap(this.$store.state.dataAnalysisId,this.mapPageNum,this.mapPageSize)
          const mapRes = await getPersonTimeOfMap(this.$store.state.dataAnalysisId,"1","999")
          // console.log("地图人次",response.data)
          var information = mapRes.data  //画地图的
          this.mapFunctionData = response.data  //表格的
          this.mapTotal = response.count  //分页的
        }else if(this.mapfuncitonType == "NumberOfPeople"){  //人数
          const response = await getMapData(this.$store.state.dataAnalysisId,this.mapPageNum,this.mapPageSize)
          const mapRes = await getPersonTimeOfMap(this.$store.state.dataAnalysisId,"1","999")
          // console.log("地图人次",response.data)
          var information = mapRes.data  //画地图的
          this.mapFunctionData = response.data  //表格的
          this.mapTotal = response.count  //分页的
        }
        // const response = await getMapData(this.$store.state.dataAnalysisId)
        // console.log("地图",response)
        // const response = await getPersonTimeOfMap(this.$store.state.dataAnalysisId,this.mapPageNum,this.mapPageSize)
        // console.log("地图",response)

        // var information = response.data  //画地图的
        // this.mapFunctionData = response.data  //表格的
        // this.mapTotal = response.count  //分页的
        var mapContainer = echarts.init(document.getElementById("mapContainer"));  //挂载echarts
        // this.$echarts.registerMap('china',china.json)


        echarts.registerMap('china', china) // 在echarts中注册使用这个文件
        let option = {
          tooltip: {
           formatter:function(params){
            // console.log("悬浮框",params)
             return "地区："+ params.name+'<br />'+params.seriesName+' :  ' + (params.value ? params.value:0)
           }//数据格式化
          },

          // tooltip: {  //这里设置提示框  先不打开
          //   trigger: 'item',  //数据项图形触发
          //   backgroundColor: "#f1fbfb",  //提示框浮层的背景颜色。
          //   //字符串模板(地图): {a}（系列名称），{b}（区域名称），{c}（合并数值）,{d}（无）
          //   formatter: '地区：{b}<br/>人次：{c}'
          //   },

          visualMap:{
            min: 0,
            max: 400,
            left: 'left',
            top:'bottom',
            text:['H','L'],

            inRange:{
              color: ['#f1fbfb','#0c73dc'],
            },
            show:true
          },

          geo:{
            map:'china',
            roam: true,
            zoom:1.23,
            label: {
              // 显示的省份
              normal: {
                show: false,
                fontSize:'10px',
                color: 'rgba(0,0,0,0.7)'
              }
            },
            itemStyle: {
              normal:{
              borderColor: 'rgba(0,0,0,0.2)',
              areaCoLor:'#fff',
              },
              emphasis:{
                areaColor: '#F3B329',
                shadowoffsetX:0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor:'rgba(0,0,0, 0.5)'
              }
            }
          },

          dataset:{
            // source:information
          },

          series :[
            {
              // name:'人数',
              name: this.mapfuncitonType == "personTime"?"人次":"人数",
              type:'map',
              geoIndex:0,
              // this.chartdata.map(item => item.number),
              // data:[information.province,information.number]


              data:JSON.parse(JSON.stringify(information).replace(/province/g,'name').replace(/number/g, 'value'))

              // [
              // // information
              //   // {
              //   //     value: information.map(item => item.number),
              //   //     name: information.map(item => item.province),
              //   // },
              //   { name: '山东省', value: '350000' },
              //   { name: '广东省', value: '35' },
              // ],
            }
        ]
        }


        // var option = {
        //     title: {  //标题样式
        //         text: 'ECharts 中国地图',
        //         x: "center",
        //         textStyle: {
        //             fontSize: 18,
        //             color: "red"
        //         },
        //     },
        //     tooltip: {  //这里设置提示框
        //         trigger: 'item',  //数据项图形触发
        //         backgroundColor: "red",  //提示框浮层的背景颜色。
        //         //字符串模板(地图): {a}（系列名称），{b}（区域名称），{c}（合并数值）,{d}（无）
        //         formatter: '地区：{b}<br/>模拟数据：{c}'
        //     },
        //     visualMap: {//视觉映射组件
        //         top: 'center',
        //         left: 'left',
        //         min: 10,
        //         max: 500000,
        //         text: ['High', 'Low'],
        //         realtime: false,  //拖拽时，是否实时更新
        //         calculable: true,  //是否显示拖拽用的手柄
        //         inRange: {
        //             color: ['lightskyblue', '#0c73dc']
        //         }
        //     },
        //     series: [
        //         {
        //             name: '模拟数据',
        //             type: 'map',
        //             mapType: 'china',
        //             roam: false,//是否开启鼠标缩放和平移漫游
        //             itemStyle: {//地图区域的多边形 图形样式
        //                 normal: {//是图形在默认状态下的样式
        //                     label: {
        //                         show: true,//是否显示标签
        //                         textStyle: {
        //                             color: "black"
        //                         }
        //                     }
        //                 },
        //                 zoom: 1.5,  //地图缩放比例,默认为1
        //                 emphasis: {//是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
        //                     label: { show: true }
        //                 }
        //             },
        //             top: "3%",//组件距离容器的距离
        //             data: [
        //                 { name: '北京市', value: '350000' },
        //                 { name: '天津市', value: '120000' },
        //                 { name: '上海市', value: 300000 },
        //                 { name: '重庆市', value: 92000 },
        //                 { name: '河北省', value: 25000 },
        //                 { name: '河南省', value: 20000 },
        //                 { name: '云南省', value: 500 },
        //                 { name: '辽宁省', value: 3050 },
        //                 { name: '黑龙江', value: 80000 },
        //                 { name: '湖南', value: 2000 },
        //                 { name: '安徽', value: 24580 },
        //                 { name: '山东', value: 40629 },
        //                 { name: '新疆', value: '36981' },
        //                 { name: '江苏', value: 13569 },
        //                 { name: '浙江', value: 24956 },
        //                 { name: '江西', value: 15194 },
        //                 { name: '湖北', value: 41398 },
        //                 { name: '广西', value: 41150 },
        //                 { name: '甘肃', value: 17630 },
        //                 { name: '山西', value: 27370 },
        //                 { name: '内蒙古', value: 27370 },
        //                 { name: '陕西', value: 97208 },
        //                 { name: '吉林', value: 88290 },
        //                 { name: '福建', value: 19978 },
        //                 { name: '贵州', value: 94485 },
        //                 { name: '广东', value: 89426 },
        //                 { name: '青海', value: 35484 },
        //                 { name: '西藏', value: 97413 },
        //                 { name: '四川', value: 54161 },
        //                 { name: '宁夏', value: 56515 },
        //                 { name: '海南', value: 54871 },
        //                 { name: '台湾', value: 48544 },
        //                 { name: '香港', value: 49474 },
        //                 { name: '澳门', value: 34594 }
        //             ]
        //         }
        //     ]
        // };

        // option.series.data = [
        //     // {
        //     //     value:  information.map(item => item.number),
        //     //     name:  information.map(item => item.province),
        //     // }
        //     {name:  "山东省",value: 200,}
        // ],

        // console.log("看看省份", option.series[0].data )

      //   getTrainData({
      //   fatherId: this.fatherId,
      // }).then((res) => {
      //   console.log(res)

      //   this.dataTree = res
      //   console.log(res)
      // }).catch((err) => {
      //   this.$message.error('获取数据失败，请稍后再试')
      // })

      var ceshi = JSON.parse(JSON.stringify(information).replace("province", "name"))
      var ceshi = JSON.parse(JSON.stringify(information).replace("number", "value"))
      // console.log("改键",ceshi)

      const bbb = JSON.parse(JSON.stringify(information).replace(/province/g,'name').replace(/number/g, 'value'))
      // console.log("改键",bbb)






      mapContainer.setOption(option)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .SuspensionFunction{
    width: 100%;
    //border-left: 1px solid #d9d9d9;
    .SuspensionFunction-title{
      background-color: #fff;
      height: 50px;line-height: 50px;
      border-bottom: 1px solid #F5F5F5;
      display: flex;
      justify-content: space-between;
      .title{
        font-weight: bold;
        margin-left: 10px;
      }
    }
    .dataAnalys{
      padding: 40px;
      background-color: #f0f2f5;
      height: 100%;

    .mapBox{
      height: 500px;
      width: 100%;
      background-color: #fff;
      margin-top: 10px;

      p{
        font-weight:700;
        padding-top:15px;
        padding-left:15px;
      }

      .map{
        width:100%;
        height: 450px ;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        padding-top:0;
        // background-color: pink;

        #mapContainer{
          width:48% ;
          height: 100%;
          // border:1px solid  #d3a3ff;
          // background-color: pink;
        }

        .mapTable{
          width:48% ;
          height: 100%;
          // background-color: pink;
        }

      }
    }


    .PieChart{
      height: 500px;
      width: 100%;
      background-color: #fff;
      margin-top: 10px;

      p{
        font-weight:700;
        padding-top:15px;
        padding-left:15px;
      }

      .pieLayout{
        display: flex;
        justify-content: space-between;
        height: 88%;
        padding: 20px;
        #piepic{
            width:62% ;
            height: 100%;
             //background-color: pink;
        }
        .piepic2{
          width:38% ;
          height: 100%;
          // background-color: pink;
        }
      }
    }

      .DataGrid{
        display: flex;
        align-items: center;
        width: 100%;
        background-color: #fff;
        height: 15vh;
        border-radius: 5px;

        .boxColor1{
          width: 20%;
          height: 100%;
          background-color: #d3a3ff;
          border-radius: 5px 0 0 5px;
        }

        .boxColor2{
          width: 20%;
          height: 100%;
          background-color: #f4742f;
          border-radius: 5px 0 0 5px;
        }

        .boxColor3{
          width: 20%;
          height: 100%;
          background-color: #5adebc;
          border-radius: 5px 0 0 5px;
        }
        .dataBox{
          width: 80%;
          // background-color: #2fa137;
          border-radius: 0 5px  5px /0;


          .ViewingData{
            width: 100%;
            text-align: center;
            margin-bottom: 6px;
            color: #a1a1a1;
          }
          .number{
            width: 100%;
            text-align: center;

            h2{
              margin-bottom: 0;
              font-size:28px
            }
          }

        }
      }
    }

  }

  .NumberOfHomepageVisitors {
    margin-top: 10px;
    width: 100%;
    background-color: #ffffff;
    height: 54px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    position: relative;

   p {
     font-weight: bold;
    //  padding-top:15px;
    //  padding-left:15px;
      margin-top: 0;
      margin-bottom: 0;
   }

   .tabAnddateChange{
      margin-right:10px;
      .dataPicker {
        margin-right:20px;

      .a-range-picker {
          width: 70%;
      }
    }
   }
}

/*表格size 为small 时表头的颜色*/
 ::v-deep .ant-table-body  {
    // background-color: #d4eaff;
    // font-weight: 800;
  }

  ::v-deep .ant-table-thead{
     background-color: #d4eaff;
  }

  ::v-deep .ant-table-small > .ant-table-content > .ant-table-body{
    margin:0
  }
  ::v-deep  .ant-table-body{
    margin: 0px !important;
        // background-color: #d4eaff;
}
::v-deep .ant-table-column-title{
  font-weight: 550;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
    background-color: #54adff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #d7d7d7;
    background-color: #54adff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: #fff;
    border-color: #d7d7d7;
}

.ant-radio-button-wrapper{
  color: rgba(0, 0, 0, 0.75);
}

.ant-radio-button-wrapper-checked[data-v-5fc0a973]:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    border-color: #d7d7d7;
    background-color: #54adff;
}

</style>
